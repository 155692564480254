
import CatalogCard from '@/components/catalog_card'
import CategoryBanners from '@/components/category_banners'
import LoadingSpinner from '@/components/loading_spinner'
import _ from 'underscore'

export default {
  components: {
    CatalogCard,
    CategoryBanners,
    LoadingSpinner
  }

  data: -> {
    products: []
    promotions_active: false
    promotions_active_loading: true
  }

  computed: {
    categories: ->
      _.sortBy(@$store.state.root_categories.categories, 'name')

    items: ->
      _.sortBy(@categories.concat(@products), 'name')

    offers: ->
      @$store.state.category_banners.offers

    supplier_id: ->
      @$store.state.suppliers.current_id

    loading: ->
      @$store.state.root_categories.loading || @$store.state.category_banners.loading || !!@promotions_active_loading

    feature_page_links: ->
      links = []
      if @$store.getters['application/is_agency']
        if @promotions_active
          links.push { target: '/leaflets_promotions', text: 'catalog_page_promotions', icon: 'fas fa-star' }
        if @$store.state.application.features.stokvel
          links.push { target: '/stokvel', text: 'catalog_page_stokvel', icon: 'fas fa-calendar' }
        if @$store.state.application.features.combos
          links.push { target: '/combos', text: 'catalog_page_combos', icon: 'fas fa-boxes' }
      else if !@$store.getters['suppliers/current']?.id
        links.push { target: '/leaflets', text: 'catalog_page_leaflets', icon: 'fas fa-star' }
        links.push { target: '/agencies', text: 'catalog_page_stores', icon: 'fas fa-store' }
      links
  }

  created: ->
    @fetch_content()
    @fetch_root_products()
    @$store.commit('layout/page_nav', {title: @$t('catalog_page_header')})

  destroyed: ->
    @$store.commit('root_categories/clear')
    @$store.commit('category_banners/clear')

  updated: ->
    @$store.commit('bootstate/page_loaded')

  methods: {
    fetch_content: ->
      @$store.dispatch('root_categories/fetch')
      @$store.dispatch('category_banners/fetch', { category_id: 'root', supplier_id: @supplier_id })
      @$store.dispatch('offers/promotions_active')
        .then (active) => @promotions_active = active
        .catch () -> @promotions_active = false
        .finally () => @promotions_active_loading = false

    fetch_root_products: ->
      @$store.dispatch('product/fetch_root_products')
      .then (products) =>
        @products = _.map(products, (p) -> p.target = 'product'; p)
      .catch (error) -> console.error(error)
  }

  watch: {
    supplier_id: (v) ->
      @fetch_content()
  }
}
